import { PureComponent } from 'react';

// Material UI imports
import {
  Typography,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  CircularProgress,
  Grid,
  InputAdornment,
  withStyles
} from '@material-ui/core';

// Material UI icons
import SearchIcon from '@mui/icons-material/SearchRounded';

// Responsive dialog
import ResponsiveDialog from 'components/ResponsiveDialog';

// Autocomplete component
import { Autocomplete } from '@material-ui/lab';

// Snackbar provider
import { withSnackbar } from 'notistack';

// To clean up exports
import compose from 'recompose/compose';

// To validate the form input
import validate from 'validate.js';

import {
  getMaterialLevelInfo,
  getMaterialLevelInfoPTE
} from 'services/materials';

// Login schema
import schema from './schema';

// Component styles
import styles from './styles';

class BookingDialog extends PureComponent {
  constructor(props) {
    super(props);

    // Initialize the state
    this.state = {
      open: false,
      isValid: false,
      values: {},
      touched: {},
      errors: {},
      material: '',
      materialOptions: undefined,
      course: '',
      courseOptions: undefined
    };

    // Bind functions
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.confirm = this.confirm.bind(this);
    this.onMaterialChange = this.onMaterialChange.bind(this);

    // Bind event handlers
    this.handleChange = this.handleChange.bind(this);

    // Create the events object
    this.events = {
      close: this.close,
      confirm: this.confirm
    };

    // Set the signal to true to allow for state updates
    this.ready = true;
  }

  async componentDidMount() {
    // Grab the topic options from the state
    const { materialOptions } = this.state;
    const { enqueueSnackbar } = this.props;

    // If we've already retrived the material options, return
    if (materialOptions && materialOptions.length > 0) return;

    // Grab the materials endpoint from the props
    const { auth, student } = this.props;

    // Wrap in a try-catch to handle any errors
    try {
      // Retrieve the materials from the API
      const materials = await auth.api.materials.getMaterials();

      // Retrieve the courses from the API
      const courses = await auth.api.materials.getCourses(
        materials[0] ? materials[0].id : 'greetings&introductions'
      );

      // Update the state with the materials
      if (this.ready) {
        this.setState({
          materialOptions: materials,
          courseOptions: courses,
          material: materials[0].id,
          course: courses[0].id
        });
      }
    } catch (error) {
      // Log the error to the console
      console.error(error);

      // Show an error snackbar
      enqueueSnackbar(
        'There was an error when retriving the lesson material options for your booking.',
        {
          variant: 'error'
        }
      );
    }
  }

  componentWillUnmount() {
    // Set the ready flag to false to prevent any further state changes
    this.ready = false;
  }

  async onMaterialChange(material) {
    // Grab the materials endpoint from the props
    const { auth, enqueueSnackbar } = this.props;

    // Save a copy of the old material state
    const {
      material: oldMaterial,
      materialOptions: oldMaterialOptions,
      course: oldCourse,
      courseOptions: oldCourseOptions
    } = this.state;

    // Wrap in a try-catch to handle any errors
    try {
      // Update the state to begin loading
      if (this.ready) {
        this.setState({
          material,
          course: '',
          courseOptions: undefined,
          isValid: false,
          values: {
            ...this.state.values,
            topic: ''
          }
        });
      }

      // Retrieve the courses from the API
      const courses = await auth.api.materials.getCourses(material);

      // Update the state with the materials
      if (this.ready) {
        this.setState({
          course: courses[0].id,
          courseOptions: courses
        });
      }
    } catch (error) {
      // Log the error to the console
      console.error(error);

      // Reset the state
      if (this.ready) {
        this.setState({
          material: oldMaterial,
          materialOptions: oldMaterialOptions,
          course: oldCourse,
          courseOptions: oldCourseOptions
        });
      }

      // Show an error snackbar
      enqueueSnackbar('An error occured when loading the streams.', {
        variant: 'error'
      });
    }
  }

  // Create a open function to hook into in
  // the parent component
  open(timestamp, booking) {
    this.setState({
      values: {
        introduction: true,
        correctMistakes: true
      },
      timestamp,
      booking,
      open: true,
      isValid: false
    });
  }

  // Create a close function to hook into in
  // the parent component
  close() {
    this.setState({
      open: false
    });
  }

  confirm() {
    // Destructure the state
    const {
      isValid,
      values,
      booking,
      timestamp,
      material,
      course,
      materialOptions,
      courseOptions
    } = this.state;

    // Grab the onConfirm and enqueueSnackbar functions from thep rops
    const { onConfirm, enqueueSnackbar } = this.props;

    // If the form is valid, submit it
    if (isValid) {
      // Trigger the onConfirm function
      this.close();

      // Get the current material and course
      const currentMaterial = materialOptions.find(
        (entry) => entry.id === material
      );
      const currentCourse = courseOptions.find((entry) => entry.id === course);

      // Return the material
      onConfirm({
        values: {
          ...values,
          topic: {
            material: currentMaterial.id,
            course: currentCourse.id,
            lesson: values.topic.id
          }
        },
        booking,
        timestamp
      });
    } else {
      // Show an error snackbar
      enqueueSnackbar(
        'Please correctly fill out all details before submitting.',
        { variant: 'error' }
      );
    }
  }

  handleChange(event) {
    // Grab the values from the state
    const { values, touched } = this.state;

    // Get the newly changed values
    const newValues = {
      ...values,
      [event.target.name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value
    };

    // Validate the state's values
    const errors = validate(newValues, schema);

    // Update the state
    this.setState({
      values: newValues,
      touched: {
        ...touched,
        [event.target.name]: true
      },
      isValid: !errors,
      errors: errors || {}
    });
  }

  hasError(field) {
    // Get the errors from the state
    const { touched, errors } = this.state;
    return !!(touched[field] && errors[field]);
  }

  render() {
    // Get the current from state
    const {
      values,
      errors,
      isValid,
      open,
      material,
      course,
      materialOptions,
      courseOptions
    } = this.state;

    // Retrieve out styles from the props
    const { classes, student, selectedBooking } = this.props;
    const isTutorPte = selectedBooking?.profile?.tutor?.isPte || false;

    // Find the current course
    const currentCourse =
      courseOptions && courseOptions.find((entry) => entry.id === course);
    const isA2 = ['AA2'].includes(currentCourse?.abbreviation);
    const subscription = student?.stripe?.subscription;
    const categories = subscription?.currentSubscription?.categories || [];

    // Check if the subscription is active and has categories
    const hasValidSubscription =
      subscription && subscription.currentSubscription && categories.length > 0;
    const ptePlans = [
      'price_1R4HRwHns6zXPgc8A2KYJiq8',
      'price_1R4HKZHns6zXPgc866zuYJzK',
      'price_1QVCUcHns6zXPgc8e7zIwaX8'
    ];

    // Check for one-on-one PTE subscription only
    const hasPTESubscription =
      hasValidSubscription &&
      categories.some((cat) => ptePlans.includes(cat.priceId));
    const haveWorkplaceSubscription =
      hasValidSubscription &&
      categories.some((cat) => {
        return cat.priceId === 'price_1QVCTaHns6zXPgc8CyRjvfHI';
      });
    const haveAA2Subscription =
      hasValidSubscription &&
      categories.some((cat) => {
        return cat.priceId === 'price_1R4HRwHns6zXPgc8A2KYJiq8';
      });
    const haveBB1Subscription =
      hasValidSubscription &&
      categories.some((cat) => {
        return cat.priceId === 'price_1QVCUcHns6zXPgc8e7zIwaX8';
      });
    const haveBB2Subscription =
      hasValidSubscription &&
      categories.some((cat) => {
        return cat.priceId === 'price_1R4HKZHns6zXPgc866zuYJzK';
      });

    return (
      <ResponsiveDialog
        title="Confirm Booking Details"
        events={this.events}
        open={open}
        confirmDisabled={!isValid || !materialOptions || !courseOptions}>
        <Typography className={classes.header} variant="h5">
          Topic
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormControl variant="filled" fullWidth>
              <InputLabel id="select-booking-material">Study Area</InputLabel>
              <Select
                labelId="select-booking-material"
                id="select-filled"
                disabled={!materialOptions}
                name="material"
                value={material}
                endAdornment={
                  !materialOptions && (
                    <CircularProgress
                      className={classes.adornment}
                      color="inherit"
                      size={20}
                    />
                  )
                }
                onChange={(event) => {
                  this.onMaterialChange(event.target.value);
                }}>
                {materialOptions &&
                  materialOptions.map((materialItem) => {
                    // Check if this is PTE material and if user has active PTE subscription
                    const isPTE = materialItem.name === 'PTE';

                    return (
                      <MenuItem
                        key={materialItem.id}
                        value={materialItem.id}
                        disabled={
                          isPTE
                            ? !hasPTESubscription || !isTutorPte
                            : !haveWorkplaceSubscription
                        }>
                        {materialItem.name}{' '}
                        {isPTE &&
                          (!hasPTESubscription || !isTutorPte) &&
                          ' (Requires PTE Subscription or PTE Teacher)'}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormControl variant="filled" fullWidth>
              <InputLabel id="select-booking-course">Stream</InputLabel>
              <Select
                labelId="select-booking-course"
                id="select-filled"
                disabled={!courseOptions}
                name="course"
                value={course}
                endAdornment={
                  !courseOptions && (
                    <CircularProgress
                      className={classes.adornment}
                      color="inherit"
                      size={20}
                    />
                  )
                }
                onChange={(event) => {
                  this.setState({
                    course: event.target.value,
                    isValid: false,
                    values: {
                      ...this.state.values,
                      topic: ''
                    }
                  });
                }}>
                {courseOptions &&
                  courseOptions.map((courseItem) => {
                    console.log({ courseItem });
                    return (
                      <MenuItem
                        key={courseItem.id}
                        value={courseItem.id}
                        disabled={
                          material == 'pte'
                            ? (!haveAA2Subscription &&
                                courseItem.abbreviation === 'AA2') ||
                              (!haveBB1Subscription &&
                                courseItem.abbreviation === 'BB1') ||
                              (!haveBB2Subscription &&
                                courseItem.abbreviation === 'BB2')
                            : false
                        }>
                        {courseItem.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Autocomplete
              groupBy={(option) =>
                `Level ${option.level} - ${
                  material == 'pte'
                    ? getMaterialLevelInfoPTE(isA2, option.level).name
                    : getMaterialLevelInfo(option.level).name
                }`
              }
              autoHighlight
              options={
                (currentCourse && currentCourse.lessons) || [
                  { name: 'Please select...', value: null }
                ]
              }
              getOptionLabel={(opt) => opt && opt.name}
              loading={!currentCourse}
              value={values.topic || null}
              onInputChange={(e) => {
                if (e !== null) {
                  this.handleChange({
                    target: {
                      name: 'topic',
                      value:
                        currentCourse.lessons[
                          parseInt(e.currentTarget.dataset.optionIndex, 10)
                        ]
                    }
                  });
                }
              }}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  label="Lesson Topic"
                  name="topic"
                  variant="outlined"
                  error={this.hasError('topic')}
                  helperText={this.hasError('topic') ? errors.topic[0] : null}
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <>
                        {!courseOptions && (
                          <CircularProgress color="inherit" size={20} />
                        )}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
        <div className={classes.section}>
          <Typography className={classes.header} variant="h5">
            Introduction
          </Typography>
          <RadioGroup
            aria-label="introduction"
            name="introduction"
            value={values.introduction}
            onChange={(event, value) => {
              this.handleChange({
                target: {
                  name: 'introduction',
                  value: value === 'true'
                }
              });
            }}>
            <FormControlLabel
              // eslint-disable-next-line react/jsx-boolean-value
              value={true}
              control={<Radio />}
              label="Yes, i'd like introductions at the beginning of the lesson."
            />
            <FormControlLabel
              value={false}
              control={<Radio />}
              label="No thanks, just start the lesson right away."
            />
          </RadioGroup>
        </div>
        <div className={classes.section}>
          <Typography className={classes.header} variant="h5">
            Correct Mistakes
          </Typography>
          <RadioGroup
            aria-label="correctMistakes"
            name="correctMistakes"
            value={values.correctMistakes}
            onChange={(event, value) => {
              this.handleChange({
                target: {
                  name: 'correctMistakes',
                  value: value === 'true'
                }
              });
            }}>
            <FormControlLabel
              // eslint-disable-next-line react/jsx-boolean-value
              value={true}
              control={<Radio />}
              label="Yes, please proactively correct my mistakes."
            />
            <FormControlLabel
              value={false}
              control={<Radio />}
              label="No thanks, let's just focus on the conversation."
            />
          </RadioGroup>
        </div>
        <div className={classes.section}>
          <Typography className={classes.header} variant="h5">
            Notes
          </Typography>
          <TextField
            multiline
            rows={4}
            error={this.hasError('notes')}
            fullWidth
            helperText={this.hasError('notes') ? errors.notes[0] : null}
            label="Notes"
            name="notes"
            onChange={this.handleChange}
            type="text"
            value={values.notes || ''}
            variant="outlined"
          />
        </div>
      </ResponsiveDialog>
    );
  }
}

export default compose(withStyles(styles), withSnackbar)(BookingDialog);
