import firebase from 'firebase/app';
import 'firebase/firestore';
import API from '.';

// Live listener class
import Live from './_live';

class groupSessions extends Live {
  api: API;
  constructor(api: API) {
    super();

    // Setup a reference to the API
    this.api = api;
  }

  mount(): void {
    // Setup listeners
    const role = this.api.credentials?.role;
    const currentUserId = firebase.auth().currentUser?.uid;

    if (role && ['student', 'tutor'].includes(role) && currentUserId) {
      this.listener(
        'groupsessionsMyLiveBookings',
        firebase.firestore().collection('groupsessions')
      );
    }
  }

  async searchGroupSessions(query: any): Promise<any> {
    // Determine the user's local timezone dynamically
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Include the userTimezone in the query payload
    const payload = {
      data: query,
      timezone: userTimezone
    };

    return this.api._http(
      'groupsessions/search',
      'POST',
      { data: payload },
      false
    );
  }

  /*
    API endpoint for making a booking
  */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async bookGroupSessionBooking(booking: any): Promise<void> {
    // Check whether we're the right role before making the request
    this.api.enforceRole('student');

    console.log('booking', booking);

    // Make the request and get the response
    return await this.api._http(
      `groupsessions/session/book`,
      'PUT',
      { data: { booking } },
      false
    );
  }

  async getGroupSessionsHistory(
    startDate: number,
    endDate: number
  ): Promise<any> {
    const role = this.api.credentials?.role;
    if (!role || !['student', 'tutor'].includes(role)) {
      throw new Error('Unauthorized access');
    }

    const currentUserId = firebase.auth().currentUser?.uid;
    if (!currentUserId) {
      throw new Error('User not authenticated');
    }

    // Fetch group sessions where the 'when' field is within the date range
    const groupSessionsSnapshot = await firebase
      .firestore()
      .collection('groupsessions')
      .get();

    const groupSessionsHistory: any[] = [];

    groupSessionsSnapshot.forEach((doc) => {
      const groupSession = doc.data();
      const groupSessionId = doc.id;
      const sessionBookings = groupSession.bookings || [];

      // Filter bookings based on their 'when' and 'status'
      const filteredBookings = sessionBookings.filter((booking: any) => {
        console.log({ status: booking.status, groupSessionId });
        const bookingWhen = booking.when;
        const isValidStatus = [
          'tutor-absent',
          'student-absent',
          'pending-feedback',
          'completed',
          'cancelled'
        ].includes(booking.status);

        // if role is tutor filter booking.tutor.id === currentUserId

        const isWithinRange =
          bookingWhen >= startDate && bookingWhen <= endDate;

        if (role === 'tutor') {
          return (
            isValidStatus &&
            isWithinRange &&
            booking.tutor.uid === currentUserId
          );
        }
        // if role is student filter booking.studenIds.includes(currentUserId)
        if (role === 'student') {
          return (
            isValidStatus &&
            isWithinRange &&
            booking?.studentIds &&
            booking.studentIds.includes(currentUserId)
          );
        }
        // return isValidStatus && isWithinRange;
      });

      if (filteredBookings.length > 0) {
        groupSessionsHistory.push({
          id: doc.id,
          ...groupSession,
          bookings: filteredBookings
        });
      }
    });

    return groupSessionsHistory?.sort((a, b) => b.when - a.when);
  }
  async joinGroupSession(id: string, bookingId: string) {
    // Run the HTTP function
    return this.api._http(
      `groupsessions/groupsession/${id}/${bookingId}/join`,
      'PUT',
      { data: {} },
      false
    );
  }
  async submitFeedback(
    groupid: string,
    bookingId: string,
    studentid: string,
    feedback: any
  ): Promise<void> {
    // Check whether we're the right role before making the request
    this.api.enforceRole('tutor');

    //get group session by id
    const groupSession = await firebase
      .firestore()
      .collection('groupsessions')
      .doc(groupid)
      .get();

    const updatedBookings = groupSession
      .data()
      ?.bookings.map((booking: any) => {
        if (booking.bookingId === bookingId) {
          const updatedStudents = booking.students.map((student: any) => {
            if (student.id === studentid) {
              return {
                ...student,
                feedback,
                status: 'completed'
              };
            }
            return student;
          });

          const allStudentsHaveFeedback = updatedStudents.every(
            (stu: any) => stu.feedback
          );

          return {
            ...booking,
            students: updatedStudents,
            status: allStudentsHaveFeedback ? 'completed' : 'pending-feedback'
          };
        }
        return booking;
      });
    console.log('updatedBookings', updatedBookings);
    // update group session with updated bookings
    await firebase
      .firestore()
      .collection('groupsessions')
      .doc(groupid)
      .update({ bookings: updatedBookings });
  }
  async viewFeedback(id: string, bookingId: string): Promise<any> {
    try {
      // Check whether we're the right role before making the request
      this.api.enforceRole('student');

      const currentUserId = firebase.auth().currentUser?.uid;
      const groupSession = await firebase
        .firestore()
        .collection('groupsessions')
        .doc(id)
        .get();

      const updatedBookings = groupSession
        .data()
        ?.bookings.map((booking: any) => {
          if (booking.bookingId === bookingId) {
            const updatedStudents = booking.students.map((student: any) => {
              if (student.id === currentUserId) {
                return {
                  ...student,
                  viewFeedback: true,
                  status: 'completed'
                };
              }
              return student;
            });

            return {
              ...booking,
              students: updatedStudents
            };
          }
          return booking;
        });

      // update group session with updated bookings
      await firebase
        .firestore()
        .collection('groupsessions')
        .doc(id)
        .update({ bookings: updatedBookings });
    } catch (error) {
      // Handle errors, log them, or throw a specific error based on your needs
      console.error('Error retrieving or updating feedback:', error);
      throw error;
    }
  }
}

export default groupSessions;
