export default {
  pronunciation: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 10,
      maximum: 200,
      tooShort: 'needs to have minimum 10 words',
      tooLong: 'needs to have maximum 200 words',
      tokenizer: function (value) {
        return value.split(/\s+/g);
      }
    }
  },

  vocabulary: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 10,
      maximum: 200,
      tooShort: 'needs to have minimum 10 words',
      tooLong: 'needs to have maximum 200 words',
      tokenizer: function (value) {
        return value.split(/\s+/g);
      }
    }
  },
  grammar: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 10,
      maximum: 200,
      tooShort: 'needs to have minimum 10 words',
      tooLong: 'needs to have maximum 200 words',
      tokenizer: function (value) {
        return value.split(/\s+/g);
      }
    }
  },
  listening: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 10,
      maximum: 200,
      tooShort: 'needs to have minimum 10 words',
      tooLong: 'needs to have maximum 200 words',
      tokenizer: function (value) {
        return value.split(/\s+/g);
      }
    }
  },
  behaviour: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 10,
      maximum: 200,
      tooShort: 'needs to have minimum 10 words',
      tooLong: 'needs to have maximum 200 words',
      tokenizer: function (value) {
        return value.split(/\s+/g);
      }
    }
  }
};
export const PteSchema = (values) => {
  // Check if at least one field is filled
  const isAnyFieldFilled =
    (values.speaking && values.speaking.trim()) ||
    (values.writing && values.writing.trim()) ||
    (values.reading && values.reading.trim()) ||
    (values.listening && values.listening.trim());

  // Create a conditional schema - only validate filled fields
  const schema = {};

  // Only add validation for fields that have content
  if (values.speaking && values.speaking.trim()) {
    schema.speaking = {
      length: {
        minimum: 20,
        maximum: 200,
        tooShort: 'Needs to have at least 20 words',
        tooLong: 'Needs to have a maximum of 200 words',
        tokenizer: (value) => value.split(/\s+/g)
      }
    };
  }

  if (values.writing && values.writing.trim()) {
    schema.writing = {
      length: {
        minimum: 20,
        maximum: 200,
        tooShort: 'Needs to have at least 20 words',
        tooLong: 'Needs to have a maximum of 200 words',
        tokenizer: (value) => value.split(/\s+/g)
      }
    };
  }

  if (values.reading && values.reading.trim()) {
    schema.reading = {
      length: {
        minimum: 20,
        maximum: 200,
        tooShort: 'Needs to have at least 20 words',
        tooLong: 'Needs to have a maximum of 200 words',
        tokenizer: (value) => value.split(/\s+/g)
      }
    };
  }

  if (values.listening && values.listening.trim()) {
    schema.listening = {
      length: {
        minimum: 20,
        maximum: 200,
        tooShort: 'Needs to have at least 20 words',
        tooLong: 'Needs to have a maximum of 200 words',
        tokenizer: (value) => value.split(/\s+/g)
      }
    };
  }

  // If no fields are filled, add a custom validator to one field
  if (!isAnyFieldFilled) {
    schema.speaking = {
      presence: {
        message: 'At least one field is required'
      }
    };
  }

  return schema;
};
