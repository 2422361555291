import { PureComponent, createRef } from 'react';

// Authentication
import { withAuth } from 'services/auth';

// Notifications provider
import { withSnackbar } from 'notistack';

// Router provider
import { withRouter } from 'react-router-dom';

// To handle error messages
import { getErrorMessage } from 'helpers';

// Material UI components / helpers
import { Container, withStyles, Grid } from '@material-ui/core';

// Import the booking dialog
import BookingDialog from 'components/BookingDialog';

// Import the group session booking dialog
import GroupSessionBookingDialog from 'components/GroupSessionBookingDialog';

// Export cleanup
import compose from 'recompose/compose';

// Async status
import { AsyncStatus, TabContainer } from 'components';

// Booking card
import {
  DesiredBooking,
  SearchToolbar,
  TutorBookingCard,
  GroupSessionTutorBookingCard
} from './components';

// Component styles
import styles from './styles';

import moment from 'moment';

// Prototype function for objects
function isEmpty(obj) {
  if (obj == null) return false;
  return Object.entries(obj).length === 0;
}

class Search extends PureComponent {
  constructor(props) {
    super(props);

    // Initialzie the state
    this.state = {
      loading: false,
      loadingGroupSearch: false,
      message: null,
      groupSessionMessage: null,
      error: null,
      groupSessionError: null,
      allClasses: {},
      tabIndex: 0,
      student: null,
      allGroupSessions: {},
      selectedBooking: null,
      dialogOpen: false
    };

    // Initialize the ready flag and bind any functions
    this.ready = true;

    // Create a reference to the booking dialog
    this.bookingDialog = createRef();

    // Bind state-manipulating functions
    this.onSearch = this.onSearch.bind(this);
    this.onGroupSearch = this.onGroupSearch.bind(this);
    this.confirmBooking = this.confirmBooking.bind(this);
    this.confirmGroupSessionBooking =
      this.confirmGroupSessionBooking.bind(this);
  }

  componentWillUnmount() {
    this.ready = false;
  }

  async componentDidMount() {
    const { auth } = this.props;
    const studentProfileData = await auth.api.profile.getStudentProfile(
      auth.user?.uid
    );
    if (studentProfileData) this.setState({ student: studentProfileData });
  }

  async onSearch(query) {
    // Update the state to start loading
    this.setState({
      loading: true
    });

    try {
      // Setup references to the API endpoints
      const { auth } = this.props;
      const { student } = this.state;

      // Retrive the current user's bookings from the API.
      const data = await auth.api.bookings.searchBookings(query);

      // Update the component's state with the bookings.
      if (this.ready) {
        let classesParsed = {};
        let classesParsedMock = {};
        let sortedDates = [];
        let sortedDatesMock = [];
        console.log({ data });
        const isMockStudent = student?.profile?.status === 'mock';
        data &&
          Object.keys(data.search).map((key) => {
            let tutorClasses = data.search[key];
            tutorClasses.map((item) => {
              if (
                isMockStudent &&
                item?.profile?.tutor?.status === 'training'
              ) {
                let time = moment(item.when).format('YYYYMMDD');
                if (
                  !Object.prototype.hasOwnProperty.call(
                    classesParsedMock,
                    `${time}`
                  )
                ) {
                  sortedDatesMock.push({
                    momentDate: time,
                    dateFormattedKey: time
                  });
                  classesParsedMock[`${time}`] = {
                    [key]: [
                      {
                        id: item.id,
                        info: item.info,
                        payment: item.payment,
                        profile: item.profile,
                        status: item.status,
                        student: item.student,
                        tutor: item.tutor,
                        when: item.when
                      }
                    ]
                  };
                } else if (
                  !Object.prototype.hasOwnProperty.call(
                    classesParsedMock[`${time}`],
                    `${key}`
                  )
                ) {
                  classesParsedMock[`${time}`] = {
                    ...classesParsedMock[`${time}`],
                    [key]: [
                      {
                        id: item.id,
                        info: item.info,
                        payment: item.payment,
                        profile: item.profile,
                        status: item.status,
                        student: item.student,
                        tutor: item.tutor,
                        when: item.when
                      }
                    ]
                  };
                } else {
                  classesParsedMock[`${time}`][`${key}`].push({
                    id: item.id,
                    info: item.info,
                    payment: item.payment,
                    profile: item.profile,
                    status: item.status,
                    student: item.student,
                    tutor: item.tutor,
                    when: item.when
                  });
                }
              } else if (item?.profile?.tutor?.status !== 'training') {
                let time = moment(item.when).format('YYYYMMDD');
                if (
                  !Object.prototype.hasOwnProperty.call(
                    classesParsed,
                    `${time}`
                  )
                ) {
                  sortedDates.push({
                    momentDate: time,
                    dateFormattedKey: time
                  });
                  classesParsed[`${time}`] = {
                    [key]: [
                      {
                        id: item.id,
                        info: item.info,
                        payment: item.payment,
                        profile: item.profile,
                        status: item.status,
                        student: item.student,
                        tutor: item.tutor,
                        when: item.when
                      }
                    ]
                  };
                } else if (
                  !Object.prototype.hasOwnProperty.call(
                    classesParsed[`${time}`],
                    `${key}`
                  )
                ) {
                  classesParsed[`${time}`] = {
                    ...classesParsed[`${time}`],
                    [key]: [
                      {
                        id: item.id,
                        info: item.info,
                        payment: item.payment,
                        profile: item.profile,
                        status: item.status,
                        student: item.student,
                        tutor: item.tutor,
                        when: item.when
                      }
                    ]
                  };
                } else {
                  classesParsed[`${time}`][`${key}`].push({
                    id: item.id,
                    info: item.info,
                    payment: item.payment,
                    profile: item.profile,
                    status: item.status,
                    student: item.student,
                    tutor: item.tutor,
                    when: item.when
                  });
                }
              }
            });
          });
        this.setState({
          message: data?.message,
          loading: false,
          allClasses: isMockStudent ? classesParsedMock : classesParsed
        });
      }
    } catch (error) {
      // Update the component's state to show an error
      if (this.ready) {
        this.setState({
          loading: false,
          error: getErrorMessage(error)
        });
      }

      // Log the error to the console.
      console.error(error);
    }
  }

  async onGroupSearch(query) {
    // Update the state to start loading
    this.setState({
      loadingGroupSearch: true
    });

    try {
      // Setup references to the API endpoints
      const { auth } = this.props;
      const { student } = this.state;

      // Retrive the current user's bookings from the API.
      const data = await auth.api.groupSessions.searchGroupSessions(query);

      // if (data && data.search && typeof data.search === 'object') {
      //   const groupedData = Object.entries(data.search).reduce(
      //     (acc, [uid, bookings]) => {
      //       if (!acc[uid]) {
      //         acc[uid] = {};
      //       }

      //       bookings.forEach((booking) => {
      //         const groupSessionDate = moment(booking.sessionTime).format(
      //           'YYYYMMDD'
      //         );

      //         if (!acc[uid][groupSessionDate]) {
      //           acc[uid][groupSessionDate] = [];
      //         }

      //         acc[uid][groupSessionDate].push(booking);
      //       });

      //       return acc;
      //     },
      //     {}
      //   );
      // }

      if (this.ready) {
        let classesParsed = {};
        let classesParsedMock = {};
        let sortedDates = [];
        let sortedDatesMock = [];
        const isMockStudent = student?.profile?.status === 'mock';

        data &&
          Object.keys(data.search).forEach((key) => {
            let tutorClasses = data.search[key];
            tutorClasses.forEach((item) => {
              const groupSessionDate = moment(item.sessionTime).format(
                'YYYYMMDD'
              );
              const targetClasses =
                isMockStudent && item?.tutor?.status === 'training'
                  ? classesParsedMock
                  : classesParsed;
              const targetSortedDates =
                isMockStudent && item?.tutor?.status === 'training'
                  ? sortedDatesMock
                  : sortedDates;

              if (!targetClasses[groupSessionDate]) {
                targetSortedDates.push({
                  momentDate: groupSessionDate,
                  dateFormattedKey: groupSessionDate
                });
                targetClasses[groupSessionDate] = {
                  [key]: [
                    {
                      id: item.id,
                      groupSessionId: item.groupSessionId,
                      lessonCount: item.lessonCount,
                      info: item.info,
                      payment: item.payment,
                      status: item.status,
                      student: item.student,
                      tutor: item.tutor,
                      when: item.when
                    }
                  ]
                };
              } else if (!targetClasses[groupSessionDate][key]) {
                targetClasses[groupSessionDate][key] = [
                  {
                    id: item.id,
                    groupSessionId: item.groupSessionId,
                    lessonCount: item.lessonCount,
                    info: item.info,
                    payment: item.payment,
                    status: item.status,
                    student: item.student,
                    tutor: item.tutor,
                    when: item.when
                  }
                ];
              } else {
                targetClasses[groupSessionDate][key].push({
                  id: item.id,
                  groupSessionId: item.groupSessionId,
                  lessonCount: item.lessonCount,
                  info: item.info,
                  payment: item.payment,
                  status: item.status,
                  student: item.student,
                  tutor: item.tutor,
                  when: item.when
                });
              }
            });
          });

        this.setState({
          groupSessionMessage: data?.message,
          loadingGroupSearch: false,
          allGroupSessions: isMockStudent ? classesParsedMock : classesParsed
        });
      }
    } catch (error) {
      if (this.ready) {
        this.setState({
          loadingGroupSearch: false,
          groupSessionError: getErrorMessage(error)
        });
      }
      console.error(error);
    }
  }

  async confirmBooking(data) {
    // Close the booking dialog
    const { allClasses } = this.state;
    const { booking, values } = data;
    const { enqueueSnackbar, onUpdateTab } = this.props;

    // console.log(search);
    // return;

    // Wrap in a try-catch to handle any errors
    // eslint-disable-next-line no-unreachable
    try {
      // Notify the user that we're attempting to book the slot.
      const { auth } = this.props;
      const { student } = this.state;
      enqueueSnackbar(
        `Booking ${student.profile?.status === 'mock' ? 'Demo' : ''} slot...`
      );
      // set the mock check
      values.is_mock = student.profile?.status === 'mock' ? true : false;

      // Book the slot.
      await auth.api.bookings.bookBooking(booking.tutor, booking.id, {
        ...values,
        tutor: booking?.profile?.tutor
      });

      // Remove the booking from the schedule search
      let updatedAllClasses = {
        ...allClasses,
        [moment(booking.when).format('YYYYMMDD')]: {
          [booking.tutor]: allClasses[moment(booking.when).format('YYYYMMDD')][
            booking.tutor
          ].filter((item) => item.id !== booking.id)
        }
      };

      updatedAllClasses = Object.fromEntries(
        Object.entries(updatedAllClasses).map(([key, data]) => {
          let tutors = data;
          tutors &&
            Object.entries(tutors).map(([key, data]) => {
              if (!data.length) {
                delete tutors[key];
              }
              return tutors;
            });
          return [key, tutors];
        })
      );
      this.setState({
        allClasses: updatedAllClasses
      });

      // Show a success notification
      enqueueSnackbar(
        `${student.profile?.status === 'mock' ? 'Demo' : ''} Slot booked!`,
        { variant: 'success' }
      );

      // Redirect the user to the bookings page
      onUpdateTab(1);
    } catch (error) {
      // Log the error to the console
      console.error(error);

      // Show an error snackbar
      enqueueSnackbar(getErrorMessage(error), { variant: 'error' });
    }
  }

  async confirmGroupSessionBooking(booking) {
    const { enqueueSnackbar, onUpdateTab } = this.props;

    // Wrap in a try-catch to handle any errors
    // eslint-disable-next-line no-unreachable
    try {
      // Notify the user that we're attempting to book the slot.
      const { auth } = this.props;
      const { student } = this.state;
      enqueueSnackbar(
        `Booking ${student.profile?.status === 'mock' ? 'Demo' : ''} slot...`
      );

      // Book the slot.
      await auth.api.groupSessions.bookGroupSessionBooking(
        booking.groupSessionId
      );

      // Show a success notification
      enqueueSnackbar(
        `${student.profile?.status === 'mock' ? 'Demo' : ''} Slot booked!`,
        { variant: 'success' }
      );

      // Redirect the user to the bookings page
      onUpdateTab(1);
    } catch (error) {
      // Log the error to the console
      console.error(error);

      // Show an error snackbar
      enqueueSnackbar(getErrorMessage(error), { variant: 'error' });
    }
  }

  renderSearch() {
    const { loading, error, allClasses, tabIndex, message } = this.state;

    // Convert allClasses to tabs only if it has valid data
    const filteredClasses = Object.entries(allClasses).filter(
      ([data]) => Object.keys(data).length > 0
    );

    const tabs = filteredClasses.map(([key, data]) => ({
      label: moment(key, 'YYYYMMDD').format('DD MMM YYYY'),
      component: (
        <>
          {Object.entries(data).map(([bookingKey, bookingData]) => (
            <Grid
              key={bookingKey}
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              xl={3}
              style={{ padding: '0 9px', marginBottom: 18 }}>
              <TutorBookingCard
                key={bookingKey}
                bookings={bookingData}
                tutor={bookingKey}
                onBook={(booking) => {
                  this.setState({ selectedBooking: booking });
                  // Open the booking dialog
                  this.bookingDialog.current.open(booking.id, booking);
                }}
              />
            </Grid>
          ))}
        </>
      )
    }));

    // Ensure tabIndex is within valid range
    const validTabIndex = Math.max(0, Math.min(tabIndex, tabs.length - 1));

    if (loading) {
      return <AsyncStatus loading />;
    }
    if (error !== null) {
      // Display the error message and retry button
      return <AsyncStatus error={error} onRetry={this.retryAPICall} retry />;
    }

    if (isEmpty(allClasses) || (allClasses === null && message === null)) {
      // Notify the user that they have no upcoming sessions.
      return (
        <AsyncStatus
          error={
            allClasses === null
              ? 'Enter your criteria above and press search'
              : 'No lessons that match your criteria were found'
          }
          textVariant="body1"
        />
      );
    }

    return (
      <>
        {tabs.length ? (
          <TabContainer
            tabs={tabs}
            index={validTabIndex}
            view={'row'}
            onChange={(newIndex) => this.setState({ tabIndex: newIndex })}
          />
        ) : null}
      </>
    );
  }

  renderGroupSearch() {
    const { auth } = this.props;
    const {
      loadingGroupSearch,
      groupSessionError,
      allGroupSessions,
      tabIndex,
      groupSessionMessage
    } = this.state;

    // Filter and structure classes by date for tabs
    const filteredClasses = Object.entries(allGroupSessions).filter(
      ([data]) => Object.keys(data).length > 0
    );

    const tabs = filteredClasses.map(([groupSessionDate, tutorData]) => ({
      label: moment(groupSessionDate, 'YYYYMMDD').format('DD MMM YYYY'),
      component: (
        <>
          {Object.entries(tutorData).map(([tutorId, bookings]) => (
            <Grid
              key={tutorId}
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              xl={3}
              style={{ padding: '0 9px', marginBottom: 18 }}>
              <GroupSessionTutorBookingCard
                auth={auth}
                bookings={bookings}
                tutor={tutorId}
                onBook={(booking) => {
                  this.setState({ selectedBooking: booking, dialogOpen: true });
                }}
              />
            </Grid>
          ))}
        </>
      )
    }));

    // Ensure tabIndex is within the valid range of available tabs
    const validTabIndex = Math.max(0, Math.min(tabIndex, tabs.length - 1));

    // Render loading, error, or tabs based on the current state
    if (loadingGroupSearch) {
      return <AsyncStatus loading />;
    }
    if (groupSessionError) {
      return (
        <AsyncStatus
          error={groupSessionError}
          onRetry={this.retryAPICall}
          retry
        />
      );
    }
    if (
      isEmpty(allGroupSessions) ||
      (allGroupSessions === null && groupSessionMessage === null)
    ) {
      return (
        <AsyncStatus
          error={
            allGroupSessions === null
              ? 'Enter your criteria above and press search'
              : 'No lessons that match your criteria were found'
          }
          textVariant="body1"
        />
      );
    }

    return (
      <>
        {tabs.length ? (
          <TabContainer
            tabs={tabs}
            index={validTabIndex}
            view="row"
            onChange={(newIndex) => this.setState({ tabIndex: newIndex })}
          />
        ) : null}
      </>
    );
  }

  render() {
    const { classes, auth } = this.props;
    const {
      loading,
      message,
      student,
      groupSessionMessage,
      loadingGroupSearch,
      selectedBooking,
      dialogOpen
    } = this.state;

    // Check if the user has a current subscription
    const hasSubscription = student?.stripe?.subscription?.currentSubscription;

    return (
      <>
        <Container maxWidth="lg">
          <GroupSessionBookingDialog
            open={dialogOpen}
            onClose={() => this.setState({ dialogOpen: false })}
            booking={selectedBooking}
            onConfirm={this.confirmGroupSessionBooking}
          />
          <BookingDialog
            ref={this.bookingDialog}
            auth={auth}
            student={student}
            selectedBooking={selectedBooking}
            onConfirm={this.confirmBooking}
          />
          <div className={classes.root}>
            <SearchToolbar
              onSearch={this.onSearch}
              onGroupSearch={this.onGroupSearch}
              loading={loading}
              loadingGroupSearch={loadingGroupSearch}
              message={message}
              groupSessionMessage={groupSessionMessage}
              renderOneOnOneSearch={this.renderSearch()}
              renderGroupSearch={this.renderGroupSearch()}
            />

            {/* {this.renderSearch()} */}
          </div>
        </Container>

        {hasSubscription && (
          <Container maxWidth="lg">
            <DesiredBooking enqueueSnackbar={this.props.enqueueSnackbar} />
          </Container>
        )}
      </>
    );
  }
}

export default compose(
  withSnackbar,
  withRouter,
  withAuth,
  withStyles(styles)
)(Search);
